import Intercom, {
  show as intercomShow,
  hide as intercomHide,
  update as intercomUpdate,
  shutdown as intercomShutdown
} from '@intercom/messenger-js-sdk';

/**
 * Service class to handle Intercom messenger integration
 */
class IntercomService {
  /**
   * Initialize Intercom messenger with user data
   * @param {Object} user - User object containing profile info
   * @param {Object} user.info - Basic user info like email, name and image
   * @param {Array} user.companies - List of companies user belongs to
   * @param {Object} user.preferences - User preferences like language
   */
  initialize(user = {}) {
    try {
      const {
        info: { email, name, image } = {},
        companies = [],
        preferences: { language } = {},
        userIntercomHash
      } = user;
      const company = companies.find(
        company =>
          company &&
          company.company &&
          company.company.features &&
          company.company.features.supplier360 &&
          company.company.features.supplier360.enabled
      );

      const intercomConfig = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user._id,
        user_hash: userIntercomHash,
        name,
        email,
        language,
        language_override: language ? language.substring(0, 2) : undefined,
        hide_default_launcher: true,
        avatar: image
          ? {
              type: 'avatar',
              image_url: image
            }
          : undefined,
        company: company
          ? {
              company_id: company.company ? company.company._id : undefined,
              name: company.company ? company.company.legal_name : undefined,
              country: company.company
                ? company.company.info
                  ? company.company.info.country
                  : undefined
                : undefined
            }
          : undefined
      };
      const cleanConfig = Object.fromEntries(
        Object.entries(intercomConfig).filter(
          ([_, value]) => value !== undefined
        )
      );
      Intercom(cleanConfig);
    } catch (error) {
      console.error('Error initializing Intercom:', error);
    }
  }

  /**
   * Show the Intercom messenger
   */
  show() {
    try {
      intercomShow();
    } catch (error) {
      console.error('Error showing Intercom:', error);
    }
  }

  /**
   * Hide the Intercom messenger
   */
  hide() {
    try {
      intercomHide();
    } catch (error) {
      console.error('Error hiding Intercom:', error);
    }
  }

  /**
   * Update the Intercom messenger with user data
   * @param {Object} user - User object containing profile info
   */
  update(user = {}) {
    try {
      intercomUpdate(user);
    } catch (error) {
      console.error('Error updating Intercom:', error);
    }
  }

  /**
   * Shutdown the Intercom messenger
   */
  shutdown() {
    try {
      intercomShutdown();
    } catch (error) {
      console.error('Error shutting down Intercom:', error);
    }
  }
}

export default new IntercomService();
