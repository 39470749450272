import { Auth, Fetch, Segment, Localize } from 'react-common/services';
import get from 'lodash/get';

import { bugsnagClient } from '../index';
import posthog from 'posthog-js';
import IntercomService from './Intercom';

class UserService {
  reload() {
    return Fetch.getJSON('/auth/reload').then(user => {
      const { preferences } = user;

      Auth.setUser(user);
      this.connectWithPlugins(user);
      if (preferences && preferences.language) {
        Localize.setLanguage(preferences.language);
      }
      return user;
    });
  }

  login(email = '', password, logAsEmail) {
    email = email.trim();
    return Fetch.postJSON('/auth/login', { email, password, logAsEmail }).then(
      response => {
        const { token, user } = response;
        Auth.authenticateUser(token, user);
        this.connectWithPlugins(user);
        return user;
      }
    );
  }

  register(name, email, password, phone, country) {
    return Fetch.postJSON('/auth/register', {
      name,
      email,
      password,
      phone,
      country
    });
  }

  check(email, password) {
    return Fetch.postJSON('/auth/check', { email, password });
  }

  logout() {
    Auth.deauthenticateUser();
    Segment.reset();
    IntercomService.shutdown();
    IntercomService.initialize({});
  }

  forgotPassword(email) {
    return Fetch.postJSON('/password-reset', { email });
  }

  changePassword(token, password) {
    return Fetch.postJSON('/password-new', { token, password });
  }

  changeAccountSettings(account) {
    return Fetch.putJSON(`/api/accounts/${Auth.getUser()._id}`, account);
  }

  setSupplierTags(user, tags) {
    const features = { ...user.getFeatures() };
    if (!features.supplier360) {
      features.supplier360 = {};
    }
    features.supplier360.tags = tags;
    return Fetch.patchJSON(
      `/api/companies/${user.getCompanyId()}/features`,
      features
    );
  }

  deleteSupplierTag(user, tagId) {
    return Fetch.deleteJSON(
      `/api/companies/${user.getCompanyId()}/features/supplier360/tags/${tagId}`
    );
  }

  resetPassword(oldPassword, newPassword) {
    const { email } = Auth.getUser().info;
    return Fetch.postJSON('/api/registration/local/password-change', {
      email,
      oldPassword,
      newPassword
    });
  }

  delay = async function(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  };

  connectWithPlugins(user) {
    const companyIds = get(user, 'companies', []).map(
      company => company.company_id
    );
    const lastLogin = Math.floor(Date.now() / 1000);
    // extract from Segment.identify since it does not include sending companyIds
    if (!window.analytics) {
      console.log('Window.analytics NOT found! -> User.js');
    }
    if (window.analytics) {
      console.log('Window.analytics found! -> User.js');
      try {
        window.analytics.identify(user.info.email, {
          name: user.info.name,
          email: user.info.email,
          phone: user.info.phone,
          supplier360LastLogin: lastLogin || 'value not defined',
          companyIds: companyIds
        });
      } catch (e) {
        console.log(`Catched error on segment - user: ${e}`);
      }
    }
    this.delay(3000).then(() => {
      Segment.page();
    });
    posthog.identify(user.info.email, {
      email: user.info.email,
      name: user.info.name,
      language: user.info.language
    });
    if (bugsnagClient) {
      bugsnagClient.user = {
        id: user._id,
        name: user.info.name,
        email: user.info.email
      };
    }

    if (user.preferences && user.preferences.language) {
      Localize.setLanguage(user.preferences.language);
    }

    IntercomService.initialize(user);
  }
}

export default new UserService();
